@charset "utf-8";

@import url('bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');

body {
    margin: 0;
    padding: 10px;
    font-family: "Inter", sans-serif !important;
    font-size: 13px;
    color: #000;
    font-weight: 400;
    background-color: #efefef;
}

* {
    margin: 0px;
    padding: 0px;
}


/* .clearfix {
    *zoom: 1;
} */


.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
    position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

textarea {
    resize: none;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

table {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.fltL {
    float: left;
}

.fltR {
    float: right;
}

.padding_none {
    padding: 0 !important;
}

.border_none {
    border: none !important
}

.navbar-toggle {
    background-color: #fff !important;
}

.icon-bar {
    background-color: #000 !important;
}

.p_right0 {
    padding-right: 0px !important
}

.p_left0 {
    padding-left: 0px !important
}

.m_bottom0 {
    margin-bottom: 0 !important;
}

input[type="date"] {
    text-transform: uppercase !important;
}

.Green {
    color: #37a713;
}

.Red {
    color: #e21818;
}

.Blue {
    color: #139bbf;
}

.Yellow {
    color: #feca05;
}

.Pink {
    color: #fd546d;
}



/* @font-face {
    font-family: 'ClashDisplayMedium';
    src: url('../fonts/ClashDisplayMedium.otf');
} */


.Button {
    border: none;
    padding: 11px 30px;
    text-align: center;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    display: inline-block;
    position: relative;
    font-size: 15px;
    color: #000;
    text-transform: capitalize;
    border-radius: 5px;
    outline: 0 !important;
    box-shadow: none;
    display: inline-block;
    background-color: rgba(255, 144, 0, 1);
}

.Button:hover {
    background-position: 0px 80px;
    color: #000;
}

.Button.Cancel {
    background: #d9d9d9;
    color: #000;
    margin: 0 0 0 0;
    padding: 10px 20px;
}

.switch {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    padding: 2px 0 3px 50px;
    font-weight: 400;
    font-size: 12px;
    color: #646467;
    float: right;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    height: 21px;
    border-radius: 50px;
    width: 40px;
    border: 1px solid #fd8240;
    min-width: auto;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 3px;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(45deg, #fd701e, #fd894e);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    margin: auto;
}

.switch input:checked+.slider {
    background-image: linear-gradient(45deg, #fd701e, #fd894e);
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(19px);
    transform: translateX(19px);
    background: #fff
}

.CheckBox {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600 !important;
    color: #2d2d2d;
    margin: 0 !important;
    min-height: 20px;
}

.CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.CheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 24px;
    background-color: #fff;
    border: 1px solid rgba(255, 144, 0, 1);
    border-radius: 2px;
    min-width: auto;
}

.CheckBox:hover input~.checkmark {
    background-color: rgba(255, 144, 0, 1);
    border-color: rgba(255, 144, 0, 1);
}

.CheckBox input:checked~.checkmark {
    background: rgba(255, 144, 0, 1);
}

.CheckBox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 2px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg)
}

.CheckBox input:checked~.checkmark:after {
    display: block
}

.Radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 !important;
}

.Radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.Radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fd7b33;
}

.Radio:hover input~.checkmark {
    border-color: #fd7b33;
    background-color: #fd7b33;
}

.Radio input:checked~.checkmark {
    border-color: #fd7f3b;
    background-color: #fff;
}

.Radio .checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.Radio input:checked~.checkmark:after {
    display: block
}

.Radio .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fd7f3b;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

/* .LoginArea{background-image: linear-gradient(45deg, #ff9000, #f5ab00);min-height:100vh;display:flex;align-items:center;justify-content:center;padding: 40px 0 0 0;} */
.LoginArea {
    background-image: url('../images/login-bg.png');
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 0 0;
}

.LoginArea .LoginBox {
    background-color: #fff;
    padding: 50px 50px 50px 50px;
    box-shadow: 0 0 10px #858585;
    border-radius: 10px;
    width: 500px;
    position: relative;
}

.LoginArea .LoginBox figure {
    text-align: center;
    background-color: #fff;
    margin: 0 0 20px 0;
}

/* .LoginArea .LoginBox figure img{} */
.LoginArea .LoginBox form {
    margin: auto
}

.LoginArea .LoginBox form h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    color: #000;
    text-align: center;
    font-family: 'ClashDisplayMedium';
}

.LoginArea .LoginBox form p {
    font-size: 14px;
    margin: 0 0 20px 0;
    font-weight: 600;
    text-align: center
}

.LoginArea .LoginBox form .form-group {
    margin: 0 0 20px 0;
    position: relative;
}

.LoginArea .LoginBox form .form-group label {
    display: block;
    color: #000;
    font-size: 15px;
    font-weight: 500
}

.LoginArea .LoginBox form .form-group label a {
    float: right
}

.LoginArea .LoginBox form .form-group .form-control {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.40);
    padding: 10px 15px;
    height: auto;
    border-radius: 30px;
    font-size: 15px;
    color: #000;
    font-weight: 400;
}

.LoginArea .LoginBox form .form-group .form-control:focus {
    border-color: #f98e1c
}

.LoginArea .LoginBox form .form-group .Icon {
    position: absolute;
    top: 42px;
    right: 15px;
}

.LoginArea .LoginBox form .form-group .Checkboxs {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.LoginArea .LoginBox form .form-group .Checkboxs a {
    color: #615e5e;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px
}

.LoginArea .LoginBox form .form-group .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
}

.LoginArea .LoginBox form .form-group .OTPBox .form-control {
    padding: 0;
    text-align: center;
    width: 60px;
    height: 55px;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    line-height: 30px
}

.LoginArea .LoginBox form .Button {
    display: block;
    border-radius: 30px;
}

.LoginArea .LoginBox h6 {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
    color: #000
}

.LoginArea .LoginBox h6 a {
    color: #d99507;
    font-weight: 700
}

.SuccessArea {
    background-image: url('../images/login-bg.png');
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0
}

.SuccessArea .SuccessBox {
    background-color: #fff;
    min-width: 525px;
    text-align: center;
    padding: 60px 50px;
    border-radius: 20px
}

.SuccessArea .SuccessBox span {
    width: 100px;
    display: block;
    margin: 0 auto 10px
}

.SuccessArea .SuccessBox h3 {
    font-size: 36px;
    font-weight: 800;
    margin: 0 0 10px 0;
    color: #000
}

.SuccessArea .SuccessBox p {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 20px 0
}

.SuccessArea .SuccessBox a {
    display: inline-block;
    padding: 12px 30px;
    background: linear-gradient(45deg, #f98d1c, #fbbd13);
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600
}

.Header {
    /* -webkit-box-shadow:0 2px 4px rgba(0,0,0,.08); */
    position: fixed;
    left: 250px;
    top: 0;
    background-color: #fff;
    z-index: 99;
    width: calc(100% - 250px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 0;
    height: 70px;
}

.Header .HeaderLeft {
    display: flex;
    align-items: center;
}

.Header .HeaderLeft .Toggle {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0 0;
    cursor: pointer;
    border: 1px solid #dbe2eb;
}

.Header .HeaderLeft .Toggle svg {
    margin: 0 !important;
}

.Header .HeaderLeft .SearchBox {
    position: relative;
}

.Header .HeaderLeft .SearchBox .form-control {
    height: auto;
    padding: 10px 30px 10px 15px;
    background-color: #fff;
    font-size: 14px;
    box-shadow: none;
    min-width: 250px;
    border: 1px solid #dbe2eb;
}

.Header .HeaderLeft .SearchBox span {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #afadad;
    font-size: 15px;
    font-weight: 500;
}



.Header .Logo {
    width: 235px;
    text-align: center;
    padding: 8px 0;
    float: left;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    background-color: #fff;
}

.Header .Logo img {
    width: 130px;
}

.Header .Navigation {
    transition: all 0.2s ease;
    padding: 0;
    /* height:60px; */
    /* background-image: linear-gradient(45deg,#fd701e,#fd894e); */
    display: flex;
    align-items: center;
    justify-content: end;
}

.Header .Navigation .Bell {
    display: inline-block;
    position: relative;
    margin: 0 0 0 20px;
}

.Header .Navigation .Bell .Count {
    background-color: #fff;
    position: absolute;
    color: #000;
    font-size: 11px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    text-align: center;
    right: -8px;
    top: -4px;
    font-weight: 700;
    border: 1px solid #dbe2eb;
    line-height: 19px;
}

.Header .Navigation .Bell a {
    color: #666464;
    font-size: 15px;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #dbe2eb;
}

.Header .Navigation .Avater {
    margin: 0px 0 0px 30px;
    position: relative;
    display: inline-block;
}

.Header .Navigation .Avater a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    padding: 19px 0;
}

.Header .Navigation .Avater a figure {
    width: 35px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    overflow: hidden;
    height: 35px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 5px #ddd;
}

.Header .Navigation .Avater ul {
    position: absolute;
    background: #fff;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
    -webkit-transform: perspective(600px) rotateX(-90deg);
    transform: perspective(600px) rotateX(-90deg);
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 99;
    top: 100%;
    text-align: left;
    min-width: 200px;
    display: block;
    width: auto;
}

.Header .Navigation .Avater ul li {
    margin: 0;
    border-bottom: 1px solid #d2d3d4
}

.Header .Navigation .Avater ul li:first-child {
    background-image: linear-gradient(45deg, #f98c1c, #fbb914);
    position: relative;
    padding: 15px 15px 15px 60px;
    text-align: left;
    border-bottom: 1px solid #fff;
}

.Header .Navigation .Avater ul li figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    border: 2px solid #fff;
    overflow: hidden;
    margin: 0;
}

.Header .Navigation .Avater ul li h4 {
    color: #fff;
    font-size: 17px;
    line-height: 18px
}

.Header .Navigation .Avater ul li h4 span {
    font-size: 11px;
    font-weight: 400;
    display: block;
}

.Header .Navigation .Avater ul li a {
    color: #4e4e4e;
    padding: 10px 20px;
    font-weight: 400;
    transition: 0.5s all ease-in-out
}

.Header .Navigation .Avater ul li a span {
    margin: 0 10px 0 0
}

.Header .Navigation .Avater ul li a:hover {
    background-color: linear-gradient(45deg, #fe4978, #fa754d);
    color: #fff;
}

.Header .Navigation .Avater:hover ul {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}

.Header .Navigation .FinacialBox {
    text-align: center;
}

.Header .Navigation .FinacialBox h6 {
    font-weight: 700;
    color: #000;
    font-size: 16px;
}

.Header .Navigation .FinacialBox p {
    font-size: 12px;
    font-weight: 600;
    color: #7c7c7c;
    letter-spacing: 0.5px;
}



.Header {
    position: fixed;
    left: 275px;
    top: 0;
    background-color: #efefef;
    z-index: 99;
    width: calc(100% - 285px);
    padding: 10px 0 0 0;
}

.Header .HeaderBox {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 5px #ddd;
    width: 100%;
}

.Header .HeaderLeft {
    display: flex;
    align-items: center
}

.Header .HeaderLeft .Toggle {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0 0;
    cursor: pointer;
    border: 1px solid #dbe2eb
}

.Header .HeaderLeft .Toggle svg {
    margin: 0 !important
}

.Header .HeaderLeft .SearchBox {
    position: relative
}

.Header .HeaderLeft .SearchBox .form-control {
    height: auto;
    padding: 10px 30px 10px 15px;
    background-color: #fff;
    font-size: 14px;
    box-shadow: none;
    min-width: 300px;
    border: 1px solid #dbe2eb
}

.Header .HeaderLeft .SearchBox span {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #afadad;
    font-size: 15px;
    font-weight: 500
}

.Header .Navigation {
    transition: all 0.2s ease;
    padding: 0;
    /* height:60px; */
    /* background-image: linear-gradient(45deg,#fd701e,#fd894e); */
    display: flex;
    align-items: center;
    justify-content: end;
}

.Header .Navigation .Bell {
    display: inline-block;
    position: relative;
    margin: 0 0 0 20px;
}

.Header .Navigation .Bell .Count {
    background-color: #fff;
    position: absolute;
    color: #000;
    font-size: 11px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    text-align: center;
    right: -8px;
    top: -4px;
    font-weight: 700;
    border: 1px solid #dbe2eb;
    line-height: 19px;
}

.Header .Navigation .Bell a {
    color: #666464;
    font-size: 15px;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #dbe2eb;
}

.Header .Navigation .Avater {
    margin: 0px 0 0px 30px;
    position: relative;
    display: inline-block;
}

.Header .Navigation .Avater a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    padding: 15px 0;
}

.Header .Navigation .Avater a figure {
    width: 35px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    overflow: hidden;
    height: 35px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 5px #ddd;
}

.Header .Navigation .Avater ul {
    position: absolute;
    background: #fff;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
    -webkit-transform: perspective(600px) rotateX(-90deg);
    transform: perspective(600px) rotateX(-90deg);
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 99;
    top: 100%;
    text-align: left;
    min-width: 200px;
    display: block;
    width: auto;
}

.Header .Navigation .Avater ul li {
    margin: 0;
    border-bottom: 1px solid #d2d3d4
}

.Header .Navigation .Avater ul li:first-child {
    background-image: linear-gradient(45deg, #e4e3f6, #f8eee4);
    position: relative;
    padding: 15px 15px 15px 60px;
    text-align: left;
    border-bottom: 1px solid #fff;
}

.Header .Navigation .Avater ul li figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    border: 2px solid #fff;
    overflow: hidden;
    margin: 0;
}

.Header .Navigation .Avater ul li h4 {
    color: #000;
    font-size: 17px;
    line-height: 18px
}

.Header .Navigation .Avater ul li h4 span {
    font-size: 11px;
    font-weight: 400;
    display: block;
}

.Header .Navigation .Avater ul li a {
    color: #4e4e4e;
    padding: 10px 20px;
    font-weight: 400;
    transition: 0.5s all ease-in-out
}

.Header .Navigation .Avater ul li a span {
    margin: 0 10px 0 0
}

.Header .Navigation .Avater ul li a:hover {
    background-color: rgba(255, 144, 0, 1);
    color: #fff;
}

.Header .Navigation .Avater:hover ul {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}

.Header .Navigation .FinacialBox {
    text-align: center;
}

.Header .Navigation .FinacialBox h6 {
    font-weight: 700;
    color: #000;
    font-size: 16px;
}

.Header .Navigation .FinacialBox p {
    font-size: 12px;
    font-weight: 600;
    color: #7c7c7c;
    letter-spacing: 0.5px;
}




.SidenavBar {
    height: calc(100% - 0px);
    position: fixed;
    width: 250px;
    top: 10px;
    padding: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 10px;
}

.SidenavBar .Logo {
    text-align: center;
    padding: 15px 0;
    height: 70px;
    border-bottom: 1px solid #dbe2eb;
    border-right: 1px solid #dbe2eb;
}

.SidenavBar .Logo img {
    width: 165px;
}

.SidenavBar ul::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 0;
}

.SidenavBar ul::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    transition: 0.5s all ease-in-out;
    border-radius: 10px;
    display: none;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.SidenavBar ul:hover::-webkit-scrollbar-thumb {
    display: block;
}

.SidenavBar ul::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
}

.SidenavBar ul {
    margin: 0;
    height: calc(100vh - 70px);
    overflow: auto;
    padding: 15px 0 5px 0;
}

.SidenavBar ul li {
    margin: 0 0 10px 0;
    padding: 0 10px;
}

.SidenavBar ul li a {
    display: block;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    padding: 12px 20px 12px 50px;
    transition: 0.5s all ease-in-out;
    color: #484848;
    font-size: 13px;
    text-transform: capitalize;
    position: relative;
    border-radius: 50px;
}

.SidenavBar ul li a span {
    font-size: 14px;
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    text-align: center;
    border-radius: 50px;
    line-height: 33px;
    box-shadow: 0px 0px 4px #ddd;
}

.SidenavBar ul li a span i {
    color: rgba(255, 144, 0, 1);
}

.SidenavBar ul li a.dropdown-toggle:after {
    content: '\f105';
    font-family: FontAwesome;
    position: absolute;
    top: 17px;
    right: 15px;
    font-size: 13px;
    border: none;
    font-weight: 700;
    line-height: 10px;
}

.SidenavBar ul li a:hover {
    background-color: rgba(255, 144, 0, 1);
    color: #000;
}

.SidenavBar ul li.active a {
    background-color: rgba(255, 144, 0, 1);
    color: #000;
    font-weight: 500;
}

.SidenavBar ul li a.active{
    background-color: rgba(255, 144, 0, 1);
    color: #000;
    font-weight: 500;
}

.SidenavBar ul li ol {
    position: relative !important;
    float: none;
    transform: inherit !important;
    border: none;
    margin: 0;
    padding: 0;
}

.SidenavBar ul li ol li {
    margin: 15px 0 15px 0;
    padding: 0;
}

.SidenavBar ul li ol li:last-child {
    margin-bottom: 0
}

.SidenavBar ul li ol li a {
    padding: 0 0 0 50px;
    background-color: transparent !important;
    color: #5e5e5e !important;
    font-weight: 400;
}

.SidenavBar ul li ol li.active a {
    color: #f98c1c !important;
}

.SidenavBar ul li ol li a.active{
    color: #f98c1c !important;
}

.SidenavBar ul li.active ol {
    display: block;
}

.WrapperArea {
    margin-left: 250px;
    flex: 1 1 auto;
    width: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    transition: 0.5s all ease-in-out
}

.WrapperArea .WrapperBox {
    padding: 20px 5px 20px 20px;
    background-color: #efefef;
    min-height: calc(100vh - 70px);
}

.Small-Wrapper {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin-bottom: 20px
}

.Small-Wrapper+.Small-Wrapper {
    margin-top: 20px;
}


.Header .HeaderBox .HeaderLeft .TitleBox {
    margin: 0px;
}

.Header .HeaderBox .HeaderLeft .TitleBox h4.Title span {
    font-size: 18px;
}

.TitleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;
}

.TitleBox h4.Title {
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 22px;
    color: rgba(255, 144, 0, 1);
}

.TitleBox a.TitleLink {
    background-color: rgba(255, 144, 0, 1);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
}

/* .TitleBox a.TitleLink:hover{ background-color:#04bad2 } */

.Filter {
    display: flex;
}

.Filter .form-group {
    margin: 0 10px 20px 0;
    display: inline-block;
}

.Filter .form-group:last-child {
    margin: 0 0 20px 0
}

.Filter .form-group label {
    display: block;
    font-weight: 600;
    font-size: 15px;
    color: #000;
}

.Filter .form-group .form-control {
    height: 42px;
    line-height: 25px;
    padding: 8px 10px;
    border: 1px solid #cbcaca;
    font-size: 14px;
    margin: 0 10px 0 0;
    box-shadow: none;
    color: #3e3d3d;
    font-weight: 600;
    border-radius: 5px;
}

.Overview {
    background: #f1f1f1;
    padding: 25px 25px;
    border-left: 5px solid #b5b5b5;
    border-radius: 10px;
    margin: 0 0 20px 0;
}

.Overview h2 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px
}

.Overview aside {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.Overview aside i {
    font-size: 30px;
    color: #000
}

.Overview aside h3 {
    font-size: 33px;
    color: #000;
    font-weight: 800
}

.CommonForm h4 {
    font-size: 16px;
    padding: 15px 20px;
    background-color: #f1efef;
    font-weight: 700;
    color: #000;
    margin: 0 0 20px 0px;
}

.CommonForm .form-group {
    margin: 0 0 20px 0;
    position: relative;
}

.CommonForm .form-group .form-group {
    margin: 0 0 0 0
}

.CommonForm .form-group label {
    display: block;
    color: #000;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 0 10px 0
}

.CommonForm .form-group .form-control {
    height: auto;
    padding: 10px 10px 10px 15px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
}

.CommonForm .form-group .UserPlan {
    display: block;
    text-align: center;
}

.CommonForm .form-group .UserPlan li {
    display: inline-block;
    margin: 0 15px;
}

.CommonForm .form-group .Avaialable li {
    display: inline-block;
    width: 24%;
    margin: 0 0 15px 0;
}

.UploadBox .Upload {
    border: 2px dashed #b9b9b9;
    padding: 37px 0;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    width: 160px;
    height: 135px;
    text-align: center;
    background-color: #fff;
}

.UploadBox .Upload i {
    font-size: 24px
}

.UploadBox .Upload span {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    display: block;
}

.UploadBox .Upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0
}

.UploadBox figure {
    margin: 0 auto 0;
    width: 170px
}

.CommonForm .form-group .Verify {
    color: #028107c2;
    font-weight: 800;
    position: absolute;
    top: 42px;
    right: 15px;
    cursor: pointer;
}

.CommonForm .ServiceAdd {
    background-color: #ddd;
    padding: 20px 20px 2px 20px;
    border-radius: 5px;
    margin: 0 0 20px 0;
}

.CommonForm .ServiceAdd .form-group {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.CommonForm .ServiceAdd .form-group aside {
    display: flex;
}

.CommonForm .ServiceAdd .form-group aside .form-control {
    margin: 0 10px 0 0;
    width: 250px;
}

.CommonForm .ServiceAdd .form-group aside button.Delete {
    border: none;
    background-color: #ffe5e5;
    padding: 0 15px;
    border-radius: 5px;
    color: #ed0000;
    font-size: 22px;
}

.CommonForm .form-group .ck.ck-editor {
    margin: 0 0 20px 0;
}

.CommonForm .form-group .ck.ck-label.ck-voice-label {
    display: none;
}

.CommonForm .form-group .ck.ck-content.ck-editor__editable {
    padding: 10px;
    min-height: 175px;
}

.CommonForm .form-group .ExtraFeed li {
    margin: 0 0 10px 0;
}

.CommonForm .form-group .ExtraFeed li:last-child {
    margin: 0
}

.CommonForm .form-group .ExtraFeed li h5 {
    margin: 0;
    display: inline-block;
    min-width: 220px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.CommonForm .form-group .ExtraFeed li label {
    display: inline-block;
}

.AdminAccess table tr td strong {
    font-weight: 700;
    color: #000;
}

.AdminAccess table tr td .CheckBox .checkmark {
    min-width: auto;
}

.AdminAccess table tr td .CheckBox:hover .checkmark {
    background-color: rgba(255, 144, 0, 1);
}

.CommonTabs {
    margin-bottom: 20px
}

.CommonTabs .nav-tabs {
    border: none
}

.CommonTabs .nav-tabs .nav-item {
    margin: 0 15px 0 0
}

.CommonTabs .nav-tabs .nav-item a {
    background-color: #d0cece;
    border-radius: 5px;
    padding: 11px 35px;
    border: none !important;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0;
    font-size: 15px
}

.CommonTabs .nav-tabs .nav-item .nav-link.active {
    background-color: rgba(255, 144, 0, 1);
    color: #fff
}

.ReportBox ul {
    border: 1px solid #E3E3E3;
    margin: 0;
    border-radius: 10px;
    background-color: #F6F6F6
}

.ReportBox ul li {
    border-bottom: 1px solid #ddd;
    display: flex;
    padding: 25px 30px 25px 65px;
    position: relative;
    align-items: center
}

.ReportBox ul li:last-child {
    border: none
}

.ReportBox ul li span {
    margin: 0;
    width: 60px;
    font-size: 30px;
    position: absolute;
    left: 20px;
}

.ReportBox ul li figcaption {
    width: 100%
}

.ReportBox ul li figcaption h3 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0
}

.ReportBox ul li figcaption p {
    font-size: 13px;
    color: #6d6c6c;
    font-weight: 500;
}

.ReportBox ul li aside {
    display: flex;
}

.ReportBox ul li aside a {
    display: inline-block;
    font-size: 15px;
    margin: 0 14px;
}

.StaticArea .card {
    border: 1px solid #e9e9e9;
    border-radius: 0;
}

.StaticArea .card+.card {
    margin: 20px 0 0 0;
}

.StaticArea .card .card-header {
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 0;
    padding: 15px 55px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.StaticArea .card .card-header:after {
    content: '\f107';
    font-family: FontAwesome;
    color: #000;
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: 0.5s all ease-in-out
}

.StaticArea .card .card-header[aria-expanded="true"]:after {
    transform: rotate(180deg)
}

.StaticArea .card .card-header h4 {
    font-size: 17px;
    color: #000;
    font-weight: 600;
}

.StaticArea .card .card-header h4 span {
    margin: 0 10px 0px 0;
}

.StaticArea .card .card-header a {
    color: #000;
    font-size: 14px
}

.StaticArea .card .card-body {
    padding: 15px;
}

.StaticArea .card .card-body:before {
    content: none
}

.StaticArea .card .card-body p {
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    color: #404040;
}

.StaticArea .card .card-body p+p {
    margin-top: 15px
}

.UploadBills ul li {
    margin: 0 10px 20px 0;
    display: inline-block;
    width: 32%
}

.UploadBills ul li aside {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 15px 15px 15px 60px;
    border: 1px solid #ddd;
    position: relative;
    display: flex;
    justify-content: space-between
}

.UploadBills ul li aside .Count {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    background-color: #cfcece;
    border-radius: 50px;
    text-align: center;
    color: #000;
    font-weight: 600;
    line-height: 30px
}

.UploadBills ul li aside h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000
}

.UploadBills ul li aside a {
    width: 30px;
    height: 30px;
    background-color: #cfcece;
    border-radius: 50px;
    text-align: center;
    color: #000;
    font-weight: 600;
    line-height: 30px;
    display: inline-block;
    margin: 0 0 0 7px
}

.UploadBills ul li aside a.Green {
    color: #37a713;
    background-color: #d2fdc4
}

.UploadBills ul li aside a.Red {
    background-color: #ffd0d0;
    color: #e21818
}

.CommunityBox {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px #ddd
}

.CommunityBox article {
    position: relative;
    padding: 10px 10px 10px 63px;
    margin: 0 0 10px 0;
    background-color: #efefef;
    border-radius: 5px;
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.CommunityBox article .Icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    overflow: hidden;
    border: 2px solid #fff
}

.CommunityBox article h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0
}

.CommunityBox article h6 {
    font-size: 13px;
    color: #6e6e6e;
    font-weight: 600
}

.CommunityBox article .Actions a {
    font-size: 14px;
    margin: 0 0 0 5px
}

.CommunityBox figure {
    border-radius: 5px;
    overflow: hidden
}

.CommunityBox figcaption p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: 23px;
    margin: 0 0 10px 0
}

.CommunityBox figcaption ul {
    border-top: 1px solid #ddd;
    padding: 10px 0 0 0
}

.CommunityBox figcaption ul li {
    display: inline-block;
    width: 20%
}

.CommunityBox figcaption ul li a {
    font-size: 15px;
    color: #5f5f5f
}

.CommunityBox figcaption ul li a i {
    margin: 0 5px 0 0
}

.NotificationsBox ul li+li {
    margin: 15px 0 0 0
}

.NotificationsBox ul li aside {
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px
}

.NotificationsBox ul li aside p {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    margin: 0 0 10px 0
}

.NotificationsBox ul li aside h6 {
    text-align: right;
    font-size: 15px;
    font-weight: 700;
    color: #000
}

.NotificationsBox ul li aside h6 span {
    font-size: 15px;
    font-weight: 600;
    color: #747474;
}

.NotificationsBox ul li aside h5 {
    font-size: 17px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0;
}


.Pagination {
    margin: 0px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Pagination p {
    font-weight: 700;
    color: #000;
    font-size: 14px;
}

.Pagination ul {
    text-align: right
}

.Pagination ul li {
    display: inline-block;
    margin: 0 5px;
    text-align: center
}

.Pagination ul li a {
    padding: 0px 0;
    font-size: 15px;
    color: #656363;
    font-weight: 600;
}

.Pagination ul li a:hover {
    background-color: #d4d4d4
}

.Pagination ul li.active a {
    color: #fd813f;
}

.BackArrow {
    text-align: right;
    margin: 10px 0 0px 23px;
}

.BackArrow a {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.BackArrow a i {
    margin: 0 5px 0 0
}

.BackArrow a:hover {
    color: rgba(255, 144, 0, 1);
}

.UserPlanBox {
    background: #f7f7f7;
    padding: 20px 20px 5px 20px;
    border-radius: 5px;
    margin: 0 0 15px 0;
}

.CommonAddMore {
    text-align: right;
    margin: 0 0 10px 0
}

.CommonAddMore a {
    font-size: 15px;
    color: #000;
    font-weight: 700;
}

.CommonAddMore a i {
    margin: 0 5px 0 0
}

.CommonAddMore a:hover {
    color: #fd803e;
}

.DetailsArea {
    box-shadow: 0 1px 4px #ddd;
    padding: 20px;
    margin: 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}
.DetailsArea .DetailsProfile{
    margin: auto;
}

.DetailsArea .DetailsProfile figure {
    overflow: hidden;
    width: 150px;
}

.DetailsArea .DetailsProfile figure img {
    width: 100%;
}

.DetailsArea .DetailsContent {
    /* width: 100%; */
    display: flex;
}

.DetailsArea .DetailsContent article {
    width: 100%
}

.DetailsArea .DetailsContent aside {
    width: 50%
}

.DetailsArea .DetailsContent h5 {
    font-size: 18px;
    margin: 0 0 15px 0;
    font-weight: 700;
    color: #000;
}

.DetailsArea .DetailsContent p {
    margin: 0 0 15px 0
}

.DetailsArea .DetailsContent p label {
    font-weight: 700;
    text-transform: capitalize;
    width: 150px;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 10px 0 0;
    color: #000;
    font-size: 14px;
}

.DetailsArea .DetailsContent p label a {
    margin-left: 5px;
}

.DetailsArea .DetailsContent p label:after {
    content: ':';
    position: absolute;
    right: 0;
    top: 0
}

.DetailsArea .DetailsContent p span {
    font-weight: 600;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 14px;
}

.DetailsArea .DetailsContent p span a {
    color: #000;
    margin: 0 0 0 10px
}

.BuildingsPast article {
    display: flex;
}

.BuildingsPast article aside {
    background-color: #fbfbfb;
    padding: 20px;
    width: 49%;
    margin: 0 25px 20px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.BuildingsPast article aside:nth-child(even) {
    margin: 0 0px 20px 0;
}

.BuildingsPast article aside h6 {
    float: right;
    color: #000;
    font-weight: 700;
    font-size: 15px;
}

.BuildingsPast article aside h5 {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 700;
    color: #5e5c5c;
}

.BuildingsPast article aside h5 span {
    color: #000;
}

.BuildingsPast article aside p {
    font-weight: 600;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 14px;
    margin: 0 0 9px 0;
}

.BuildingsPast article aside p span {
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    color: #000;
    font-size: 14px;
}

.BuildingsPast article aside a {
    color: #3da508;
    font-weight: 700;
    font-size: 14px;
}

/*.BuildingArea{background-color: #efefef;padding: 20px;border-radius: 10px;}*/

.BuildingArea .card {
    border-radius: 7px;
    overflow: hidden;
    border: none;
}

.BuildingArea .card+.card {
    margin-top: 20px
}

.BuildingArea .card .card-header {
    cursor: pointer;
    padding: 10px 45px 10px 15px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.BuildingArea .card .card-header:after {
    content: '\f107';
    font-family: FontAwesome;
    color: #000;
    position: absolute;
    top: 28px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: 0.5s all ease-in-out
}

.BuildingArea .card .card-header[aria-expanded="true"]:after {
    transform: rotate(180deg)
}

.BuildingArea .card .card-header h3 {
    margin: 0 0 0px 0;
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.BuildingArea .card .card-header aside a {
    background-image: linear-gradient(45deg, #fd701e, #fd894e);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 15px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 0 0 5px;
}

.BuildingArea .card .card-body {
    background-color: #efefef;
    padding: 15px 0;
}

.BuildingArea .BuildingPackage {
    background-color: #fff;
    padding: 20px;
    margin: 0 0 20px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
    height: calc(100% - 20px);
}

.BuildingArea .BuildingPackage:last-child {
    /* margin:0; */
}

.BuildingArea .BuildingPackage h4 {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.BuildingArea .BuildingPackage p {
    margin: 0 0 20px 0;
    display: flex;
}

.BuildingArea .BuildingPackage p:last-child {
    margin: 0
}

.BuildingArea .BuildingPackage p label {
    font-weight: 700;
    text-transform: capitalize;
    width: 150px;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 10px 0 0;
    color: #000;
    font-size: 13px;
}

.BuildingArea .BuildingPackage.LabelLenght p label {
    width: 550px;
}

.BuildingArea .BuildingPackage p label a {
    margin-left: 5px;
}

.BuildingArea .BuildingPackage p label:after {
    content: ':';
    position: absolute;
    right: 0;
    top: 0
}

.BuildingArea .BuildingPackage p span {
    font-weight: 600;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 13px;
    width: calc(100% - 175px);
}

.BuildingArea .BuildingPackage p span i {
    font-size: 18px;
}

.BuildingArea .BuildingPackage p span a {
    color: #1a7cd1;
    margin: 0 0 0 10px;
    font-weight: 600;
}

.BuildingArea .BuildingPackage .View {
    background: rgba(255, 144, 0, 1);
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 15px;
    border-radius: 5px;
    display: inline-block;
    border: 2px solid rgba(255, 144, 0, 1);
    float: right;
}

.BuildingArea .BuildingPackage .BuildingPhotos ul li {
    display: inline-block;
    width: 175px;
    margin: 0 10px 10px 0;
}

.BuildingArea .BuildingPackage .BuildingPhotos ul li figure {
    margin: 0;
    border: 3px solid #fff;
    box-shadow: 0px 0px 4px #ddd;
    border-radius: 10px;
    overflow: hidden;
}

.BuildingArea .BuildingPackage .BuildingFeedBox {
    display: inline-block;
    width: 33%;
    margin: 5px 0;
}

.BuildingArea .BuildingPackage .BuildingFeedBox h4 {
    margin: 0;
    display: inline-block;
    min-width: 140px;
    font-size: 14px;
}

/* .BuildingArea .BuildingPackage .BuildingFeedBox+.BuildingFeedBox{} */

.BuildingArea .BuildingPackage.QuesAns p {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
}

.BuildingArea .BuildingPackage.QuesAns p label {
    font-weight: 700;
    text-transform: capitalize;
    width: auto;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 0 10px 0;
    color: #000;
    font-size: 13px;
}

.BuildingArea .BuildingPackage.QuesAns p label:after {
    display: none;
}

.BuildingArea .BuildingPackage.QuesAns p:last-child {
    margin: 0;
}

.BuildingArea .BuildingPackage.QuesAns p span {
    width: 100%;
}

.MantarUsage {
    font-size: 16px;
    font-weight: 600;
}

.AgreementDetails {
    margin-bottom: 20px
}

.AgreementDetails figcaption {
    display: flex;
}

.AgreementDetails figcaption aside {
    width: 50%;
}

.AgreementDetails figcaption p {
    margin: 0 0 15px 0;
    display: flex;
}

.AgreementDetails figcaption p label {
    font-weight: 700;
    text-transform: capitalize;
    width: 150px;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 10px 0 0;
    color: #000;
    font-size: 14px;
}

.AgreementDetails figcaption p label a {
    margin-left: 5px;
}

.AgreementDetails figcaption p label:after {
    content: ':';
    position: absolute;
    right: 0;
    top: 0
}

.AgreementDetails figcaption p span {
    font-weight: 500;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 14px;
    display: inline-block;
}

.AgreementDetails figure {
    margin: 0 0 15px 0;
}

.AgreementDetails figure img {
    width: 100%
}

.ModalBox .modal-dialog {
    max-width: 450px;
    margin: 50px auto
}

.ModalBox .modal-dialog .CloseModal {
    /* position: absolute;
    top: -13px;
    right: -13px;
    width: 26px;
    height: 26px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(0, 0, 0, 0.5);
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 15px;
    border-radius: 50%; */
    position: absolute;
    top: 15px;
    right: 30px;
    width: 26px;
    color: #000;
    font-size: 26px;
    border-radius: 50%;
}

.ModalBox .modal-dialog .Delete {
    top: 5px;
    right: 10px;
}

.ModalBox .LittleModal .modal-dialog .CloseModal {
    top: 7px;
    right: 10px;
}

.ModalBox .modal-dialog .Decline {
    margin: 0
}

.ModalBox .modal-dialog .Decline h3 {
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 5px;
    text-align: left
}

.ModalBox .modal-dialog .Decline p {
    font-size: 16px;
    color: #777;
    text-align: left
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0 0 0;
    text-align: right
}

.ModalBox .modal-dialog .Decline h4 a {
    text-transform: capitalize;
    margin: 0 0 0 30px;
    font-size: 17px;
    color: #49a0d5;
    font-weight: 400;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out
}

.ModalBox .modal-dialog .Decline h4 a:hover {
    color: #000
}

.ModalBox .modal-dialog .Category {
    padding: 10px;
}

.ModalBox .modal-dialog .Category h3 {
    text-align: left;
    font-weight: 500;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    font-size: 20px
}

.ModalBox .modal-dialog .Category .form-group {
    margin: 0 0 25px;
    position: relative
}

.ModalBox .modal-dialog .Category .form-group img {width: 100%;}

.ModalBox .modal-dialog .Category .form-group label {
    display: inline-block;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    margin: 0 0 10px 0;
    line-height: 15px;
}

.ModalBox .modal-dialog .Category .form-group .form-control {
    box-shadow: none;
    border: 1px solid #c3c3c3;
    height: auto;
    font-size: 14px;
    color: #000;
    line-height: 22px;
    padding: 13px 15px 13px;
    border-radius: 30px;
}

.ModalBox .modal-dialog .Category .form-group .Verify {
    position: absolute;
    color: #028107c2;
    font-weight: 700;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.ModalBox .modal-dialog .Category .form-group textarea.form-control {
    height: auto
}

.ModalBox .modal-dialog .Category .GatekeeperAdd {
    background: #fd7f3b;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    padding: 8px 20px;
    border-radius: 5px;
    display: inline-block;
    float: right;
    margin: -5px 0 0 0;
}

.ModalBox .MediumModal .modal-dialog {
    max-width: 750px;
    margin: 50px auto
}

/*New CSS*/

.DashboardOverview {
    background: #fff;
    padding: 20px 25px 20px 80px;
    border-radius: 15px;
    margin-bottom: 30px;
    box-shadow: -8px 12px 18px 0 #dadee8;
    height: calc(100% - 30px);
    position: relative;
}

.DashboardOverview h2 {
    font-size: 16px;
    font-weight: 500;
    color: #A3AED0;
    font-family: 'Inter';
}

.DashboardOverview article {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 0
}

.DashboardOverview span {
    font-size: 20px;
    height: 44px;
    border-radius: 50px;
    width: 44px;
    text-align: center;
    line-height: 44px;
    color: #fff;
    display: inline-block;
    position: absolute;
    left: 20px;
    background-image: linear-gradient(#FFCB8D, #F98F12);
}

.DashboardOverview article h3 {
    font-weight: 700;
    color: #1B2559;
    font-size: 20px;
}

.DashboardOverview.Overview1 {
    border-color: #f66d30
}

.DashboardOverview.Overview1 span {
    background: #f66d30;
    color: #fff
}

.DashboardOverview.Overview1 h3 {
    color: #f66d30
}

.DashboardOverview.Overview2 {
    border-color: #099d6d
}

.DashboardOverview.Overview2 span {
    background: #099d6d
}

.DashboardOverview.Overview2 h3 {
    color: #099d6d
}

.DashboardOverview.Overview3 {
    border-color: #f84563
}

.DashboardOverview.Overview3 span {
    background: #f84563
}

.DashboardOverview.Overview3 h3 {
    color: #f84563
}

.DashboardOverview.Overview4 {
    border-color: #015fea
}

.DashboardOverview.Overview4 span {
    background: #015fea
}

.DashboardOverview.Overview4 h3 {
    color: #015fea
}

.DashboardOverview.Overview5 {
    border-color: #15b3b3
}

.DashboardOverview.Overview5 span {
    background: #15b3b3
}

.DashboardOverview.Overview5 h3 {
    color: #15b3b3
}

.DashboardOverview.Overview6 {
    border-color: #42a8cf
}

.DashboardOverview.Overview6 span {
    background: #42a8cf
}

.DashboardOverview.Overview6 h3 {
    color: #42a8cf
}

.DashboardOverview.Overview7 {
    border-color: #2ab33b;
}

.DashboardOverview.Overview7 span {
    background: #2ab33b;
}

.DashboardOverview.Overview7 h3 {
    color: #2ab33b;
}

.DashboardOverview.Overview8 {
    border-color: #cd42cf;
}

.DashboardOverview.Overview8 span {
    background: #cd42cf;
}

.DashboardOverview.Overview8 h3 {
    color: #cd42cf;
}

.FilterArea {
    display: flex;
    justify-content: space-between;
}

.FilterArea .form-group {
    margin: 0 10px 0px 0;
    display: inline-block;
}

.FilterArea .form-group:last-child {
    margin: 0 0 0px 0;
}

.FilterArea .form-group label {
    display: block;
    font-weight: 600;
    font-size: 13px;
    color: #000;
}

.FilterArea .form-group .form-control {
    height: 42px;
    line-height: 25px;
    padding: 8px 10px;
    border: 1px solid #cbcaca;
    font-size: 13px;
    margin: 0 10px 0 0;
    box-shadow: none;
    color: #3e3d3d;
    font-weight: 600;
    border-radius: 5px;
}

.FilterArea .form-group .Button {
    color: #fff;
    border-radius: 5px;
}

.FilterArea .form-group .Button.Cancel {
    color: #000;
}

.TableList {
    overflow: auto;
}

.TableList::-webkit-scrollbar-thumb {
    background: #fd8343;
    transition: 0.5s all ease-in-out;
    border-radius: 10px;
    display: none;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.TableList:hover::-webkit-scrollbar-thumb {
    display: block;
}

.TableList::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
    height: 5px;
}

.TableList table {
    margin: 0 0 10px 0;
}

.TableList table tr th {
    background-color: #dcdcdc;
    font-weight: 700;
    color: #000;
    font-size: 14px;
    padding: 10px 10px
}

.TableList table tr td {
    font-weight: 600;
    color: #404040;
    padding: 10px 10px;
    font-size: 13px;
}

.TableList table tr td a {
    margin: 0 7px;
}

.TableList table tr td a:hover {
    color: #000
}

.TableList table tr th:last-child,
.TableList table tr td:last-child {
    text-align: center;
    min-width: 105px
}

.TableList table tr td:last-child a {
    color: #545454;
    margin: 0 5px;
    font-size: 14px;
    display: inline-block
}

.TableList table tr td:last-child a:hover {
    color: #000
}

.TableList table tr:nth-child(even) {
    font-weight: 500;
    /* text-transform: capitalize; */
    color: #7C8493;
    overflow: hidden;
    border-radius: 11px;
}

.TableList table tr:nth-child(even) td {
    background-color: #fff;
}

.TableList table tr:nth-child(even) td:first-child {
    border-radius: 10px 0 0 10px;
}

.TableList table tr:nth-child(even) td:last-child {
    border-radius: 0px 10px 10px 0px;
}

.TableList table tr th:first-child {
    border-radius: 10px 0 0 10px;
}

.TableList table tr th:last-child {
    border-radius: 0px 10px 10px 0px;
    background-image: none;
}

.TableList table tr td span {
    padding: 8px 10px;
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    min-width: 100px;
}

.TableList table tr td .Actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TableList table tr td .Actions button {
    padding: 6px 15px;
    color: #fff;
    border-radius: 25px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    border: none;
    font-weight: 700;
}

.TableList table tr td .Actions select {
    background-color: #fff;
    padding: 7px 6px;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #6e6e6e;
    outline: 0;
}

.TableList table tr td .Actions a.Resend {
    padding: 5px 15px;
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: block;
    text-align: center;
    font-size: 13px;
    background-color: #fd7325;
}

.TableList table tr td a.Green {
    color: #37a713;
}

.TableList table tr td a.Red {
    color: #e21818;
}

.TableList table tr td a.Blue {
    color: #139bbf;
}

.TableList table tr td a.Yellow {
    color: #feca05;
}

.TableList table tr td a.Pink {
    color: #fd546d;
}

.TableList table tr td .NewActions a {
    padding: 8px 10px;
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    min-width: 100px;
}

.TableList table tr td .NewActions a:hover {
    color: #fff;
}

.TableList table tr td .NewActions a.Green {
    background-color: #37a713;
}

.TableList table tr td .NewActions a.Red {
    background-color: #e21818;
}

.TableList table tr td .NewActions a.Blue {
    background-color: #139bbf;
}

.TableList table tr td .NewActions a.Yellow {
    background-color: #feca05;
}

.TableList table tr td .NewActions a.Pink {
    background-color: #fd546d;
}

.TableList table tr td span.Green {
    background-color: #37a713;
}

.TableList table tr td span.Blue {
    background-color: #139bbf;
}

.TableList table tr td span.Red {
    background-color: #bb0f0f;
}

.TableList table tr td span.Orange {
    background-color: #fd813f;
}

.TableList table tr td span.Pink {
    background-color: #fd546d;
}

.TableList table tr td .Actions button.Green {
    background-color: #37a713;
}

.TableList table tr td .Actions button.Blue {
    background-color: #139bbf;
}

.TableList table tr td .Actions button.Red {
    background-color: #bb0f0f;
}

.TableList table tr td .Actions button.Orange {
    background-color: #df9919;
}

.TableList table tr td .form-control {
    height: auto;
    padding: 6px 10px 6px 10px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px
}

.UserBox {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
    margin: 0 0 30px 0;
}

.UserBox figure {
    text-align: center;
}

.UserBox figure span {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 5px solid #fff;
    box-shadow: 0px 0px 8px #ddd
}

.UserBox figure span img {
    width: 100%;
    height: 100%
}

.UserBox figcaption p {
    margin: 0 0 15px 0;
    display: flex;
}

.UserBox figcaption p strong {
    font-weight: 700;
    text-transform: capitalize;
    width: 125px;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 10px 0 0;
    color: #000;
    font-size: 13px;
}

.UserBox figcaption p strong a {
    margin-left: 5px;
}

.UserBox figcaption p strong:after {
    content: ':';
    position: absolute;
    right: 0;
    top: 0
}

.UserBox figcaption p span {
    font-weight: 600;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 13px;
    display: inline-block;
}

.UserBox figcaption p .Actions {}

.UserBox figcaption p .Actions .switch {
    float: none;
    padding: 0 0 0 40px;
    margin: 0 10px 0 0;
}

.UserBox figcaption p .Actions a {
    margin: 0 10px 0 0;
}

.ModalBox .modal-dialog .Category .TableList table tr:nth-child(even) td {
    background-color: #efefef;
}



.CategoryBox {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
    margin: 0 0 30px 0;
    display: flex;
}

.CategoryBox figure {
    text-align: center;
    width: 50px;
    height: 50px;
    margin: 0;
}

.CategoryBox figure span img {
    width: 100%;
    height: 100%
}

.CategoryBox figcaption {
    width: calc(100% - 50px);
    padding: 0 0 0 8px;
}

.CategoryBox figcaption p {
    margin: 0 0 10px 0;
    display: flex;
}

.CategoryBox figcaption p strong {
    font-weight: 700;
    text-transform: capitalize;
    width: 113px;
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 10px 0 0;
    color: #000;
    font-size: 13px;
}

.CategoryBox figcaption p strong a {
    margin-left: 5px;
}

.CategoryBox figcaption p strong:after {
    content: ':';
    position: absolute;
    right: 0;
    top: 0
}

.CategoryBox figcaption p span {
    font-weight: 600;
    text-transform: capitalize;
    color: #5e5c5c;
    font-size: 13px;
    display: inline-block;
}

.CategoryBox figcaption p:last-child {
    margin: 0
}

.CategoryBox figcaption p .Actions .switch {
    float: none;
    padding: 0 0 0 40px;
    margin: 0 10px 0 0;
}

.CategoryBox figcaption p .Actions a {
    margin: 0 10px 0 0;
}

.AdminAccess table {
    border: 1px solid #e1e1e1
}

.AdminAccess table tr th {
    background-color: #e1e1e1;
    font-weight: 700;
    color: #000;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-right: 1px solid #bbb
}

.AdminAccess table tr td {
    font-weight: 600;
    color: #404040;
    padding: 10px 20px;
    font-size: 13px;
    border-bottom: 1px solid #ddd;
    border-top: none;
    vertical-align: middle;
    border-right: 1px solid #ddd
}

.AdminAccess table tr td .Read {
    display: inline-block;
    position: relative
}

.AdminAccess table tr td .Read input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.AdminAccess table tr td .Read span {
    font-size: 16px;
    color: #6e6e6e
}

.AdminAccess table tr td .Read input:checked~span {
    color: #fd701e
}

.AdminAccess table tr td .Read input:checked~span i:before {
    content: '\f070'
}

.AdminAccess table tr td .Access {
    display: inline-block;
    position: relative
}

.AdminAccess table tr td .Access input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.AdminAccess table tr td .Access span {
    font-size: 18px;
    color: #6e6e6e
}

.AdminAccess table tr td .Access input:checked~span {
    color: #fd701e
}

.AdminAccess table tr td .Access input:checked~span i:before {
    content: '\f13e'
}

.EarningBox {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 35px;
    border-radius: 10px;
    margin: 0 0 25px 0;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: calc(100% - 25px);
}

.EarningBox:before {
    content: '';
    position: absolute;
    width: 125px;
    height: 125px;
    background-color: rgb(255 255 255 / 30%);
    top: -25px;
    right: -25px;
    border-radius: 50%
}

.EarningBox:after {
    content: '';
    position: absolute;
    width: 125px;
    height: 125px;
    background-color: rgb(255 255 255 / 30%);
    bottom: -25px;
    right: -25px;
    border-radius: 50%
}

.EarningBox aside {
    width: 90px;
    height: 90px;
    border: 7px solid #fff;
    border-top-color: #fd8444;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.EarningBox aside span {
    font-size: 20px;
    color: #fff;
    font-weight: 800
}

.EarningBox article {
    text-align: right
}

.EarningBox article h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 6px 0
}

.EarningBox article h5 {
    color: #fff;
    font-size: 32px;
    font-weight: 800
}

.EarningBox.Earning1 {
    background-color: #ac70ba
}

.EarningBox.Earning2 {
    background: #00caa9
}

.EarningBox.Earning3 {
    background-color: #016bb5
}

.EarningBox.Earning4 {
    background-color: #03a4cf
}

.EarningBox.Earning5 {
    background: #e17d43
}

.EarningBox.Earning6 {
    background-color: #df1cd9
}

.EarningBox.Earning7 {
    background: #7843e1
}

.EarningBox.Earning8 {
    background-color: #1c8edf
}



.AmenitiesArea {}

.AmenitiesArea ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.AmenitiesArea ul li {
    display: inline-block;
    width: calc(100% / 5);
    margin: 0 0px 15px;
    padding: 0 5px;
}

.AmenitiesArea ul li .AmenitiesBox {
    padding: 50px 20px 35px 20px;
    position: relative;
    overflow: hidden;
    text-align: center;
    background: #fff;
    border: 1px solid #e8eef1;
    -webkit-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    -webkit-box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
    min-height: 165px;
}

.AmenitiesArea ul li .AmenitiesBox aside {
    position: absolute;
    right: 10px;
    top: 10px;
}

.AmenitiesArea ul li .AmenitiesBox aside a {
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 0 0 5px;
}

.AmenitiesArea ul li .AmenitiesBox aside a.Green {
    background-color: #00800021;
}

.AmenitiesArea ul li .AmenitiesBox aside a.Red {
    background-color: #ff000017;
}

.AmenitiesArea ul li .AmenitiesBox figure {
    width: 30px;
    height: 30px;
    margin: 0 auto 15px;
}

.AmenitiesArea ul li .AmenitiesBox figure img {
    width: 100%;
    height: 100%
}

.AmenitiesArea ul li .AmenitiesBox h6 {
    margin: 0;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 23px;
}

.AmenitiesArea ul li .AmenitiesBox .Icon {
    color: #e8e8e8;
    position: absolute;
    bottom: 5px;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    filter: alpha(opacity=0);
    left: 5px;
    -webkit-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    width: 35px;
    height: 35px;
}

.AmenitiesArea ul li .AmenitiesBox:hover span.Icon {
    bottom: 15px;
    visibility: visible;
    pointer-events: auto;
    left: 15px;
    opacity: .4;
    filter: alpha(opacity=100);
}


.ModalBox .ExtraSmallModal .modal-dialog {
    max-width: 400px;
}

.ModalBox .ExtraSmallModal2 .modal-dialog {
    max-width: 350px;
}

.ModalBox .SmallModal .modal-dialog {
    max-width: 620px;
}

.ModalBox .SmallNewModal .modal-dialog {
    max-width: 520px;
}

.ModalBox .LargeModal .modal-dialog {
    max-width: 1075px;
}

.ModalBox .modal-dialog .modal-content {
    border-radius: 32px;
}

.ModalBox .LittleModal .modal-dialog .modal-content {
    border-radius: 16px;
}

.Category .TwoButtons {
    display: flex;
    align-items: center;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}

.Category .Buttons {
    margin: 0;
}

.Category .Buttons .Button:first-child {
    margin: 0 10px 0 0;
}

.Category .Buttons .Button:last-child {
    margin: 0 0 0 10px;
}

.Category .Buttons .Button {
    width: 100%;
    background-color: rgba(245, 171, 0, 1);
    border-radius: 30px;
    color: #000;
}

.Category .Buttons .Button.Red {
    width: 100%;
    background-color: rgba(252, 52, 0, 1);
    border-radius: 30px;
    color: #fff;
}





.MultiRadioButtons {
    display: flex;
}

.MultiRadioButtons li {
    margin: 0 50px 0 0;
}

.RatingStar {
    display: flex;
    margin: 5px 0 0 0;
}

.RatingStar span {
    display: inline-block;
    margin: 0 10px 0 0;
}

.RatingStar span i {
    font-size: 24px;
}

.ModalFormArea h4.Heading {
    text-align: left;
    font-weight: 500;
    margin: 0 0 15px 0;
    font-size: 18px;
}

.Completed,
.Cancelled,
.NoShow {
    display: none;
}

.NewActions {
    display: flex;
}

.NewActions span {
    margin: 0 5px;
}

.CursorPointer {
    cursor: pointer;
}

.Category h4.Heading {
    text-align: left;
    font-weight: 500;
    margin: 0 0 15px 0;
    font-size: 18px;
}

.LogOutModalArea {
    text-align: center;
}

.LogOutModalArea span {
    width: 49px;
    height: 49px;
    margin: 0 0 20px 0;
    display: inline-block;
    background-color: rgba(254, 240, 240, 1);
    line-height: 49px;
    text-align: center;
    border-radius: 50%;
}

.LogOutModalArea h5 {
    color: rgba(26, 28, 33, 1);
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 10px 0;
}

.LogOutModalArea p {
    color: rgba(102, 112, 133, 1);
    font-size: 15px;
    letter-spacing: 0.5px;
}

.LogOutModalArea .Buttons {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.LogOutModalArea .Buttons .CancelBtn {
    background-color: rgba(224, 226, 231, 1);
    border-radius: 10px;
    color: rgba(102, 112, 133, 1);
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.5px;
    padding: 8px 25px;
    border: none;
    outline: none;
}

.LogOutModalArea .Buttons .ConfirmBtn {
    background-color: var(--brandColor);
    border-radius: 10px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.5px;
    padding: 8px 25px;
    border: none;
    outline: none;
}

.LogOutModalArea .TwoButtons {
    display: flex;
    align-items: center;
}

.LogOutModalArea .Buttons {
    margin: 10px 0 0 0;
}

.LogOutModalArea .Buttons .Button:first-child {
    margin: 0 10px 0 0;
}

.LogOutModalArea .Buttons .Button:last-child {
    margin: 0 0 0 10px;
}

.LogOutModalArea .Buttons .Button {
    width: 100%;
    background-color: rgba(245, 171, 0, 1);
    border-radius: 30px;
    color: #000;
}

.LogOutModalArea .Buttons .Button.Red {
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 30px;
    color: #000;
}

.LogOutModalArea.Delete {
    padding: 15px 0px 10px;
}

.LogOutModalArea.Delete h5 {
    font-size: 22px;
    margin: 0 0 5px 0;
}


.StaticBoxArea .panel-group {
    margin: 0;
}

.StaticBoxArea .panel-group .panel {
    border: 1px solid #e9e9e9;
    border-radius: 0;
}

.StaticBoxArea .panel-group .panel+.panel {
    margin: 20px 0 0 0;
}

.StaticBoxArea .panel-group .panel .panel-heading {
    cursor: pointer;
    background-color: #e9e9e9;
    border-radius: 0;
    padding: 15px 55px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.StaticBoxArea .panel-group .panel .panel-heading:after {
    content: '\f107';
    font-family: FontAwesome;
    color: #000;
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: 0.5s all ease-in-out
}

.StaticBoxArea .panel-group .panel .panel-heading[aria-expanded="true"]:after {
    transform: rotate(180deg)
}

.StaticBoxArea .panel-group .panel .panel-heading h4 {
    font-size: 17px;
    color: #000;
    font-weight: 500;
}

.StaticBoxArea .panel-group .panel .panel-heading h4 span {
    padding-right: 10px;
}

.StaticBoxArea .panel-group .panel .panel-heading a {
    color: #000;
    font-size: 14px
}

.StaticBoxArea .panel-group .panel .panel-body {
    padding: 15px;
}

.StaticBoxArea .panel-group .panel .panel-body:before {
    content: none
}

.StaticBoxArea .panel-group .panel .panel-body p {
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    color: #404040;
}

.StaticBoxArea .panel-group .panel .panel-body p+p {
    margin-top: 15px
}



.PannelOne {
    cursor: pointer;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden
}

.PannelOne h4 {
    font-weight: 600;
    font-size: 17px;
    color: #000;
    background-color: #e9e9e9;
    padding: 15px 15px 15px 15px;
    margin: -20px -20px 20px -20px
}

.PannelOne span {
    padding-right: 10px
}

.PayOutCommissionDetails {
    display: none;
}



.QuestionsArea {
    background-color: #fff;
    padding: 20px;
    margin: 0 0 20px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
    height: calc(100% - 20px);
}

.QuestionsArea h4 {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.QuestionsArea .QuestionBox h6 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px 0;
}

.QuestionsArea .QuestionBox h6 span {
    font-weight: 600;
}

.QuestionsArea .QuestionBox {
    margin: 0 0 15px 0;
}

.QuestionsArea .QuestionBox :last-child {
    margin-bottom: 0;
}



.BlogDetailsBox h6 {
    font-size: 15px;
    font-weight: 700;
    color: #000;
    margin: 0 0 10px 0;
}

.BlogDetailsBox h6 span {
    font-size: 15px;
    font-weight: 600;
    color: #747474;
}

.BlogDetailsBox aside p {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    margin: 0 0 10px 0
}


.AccountProfile {
    text-align: center;
}

.AccountProfile figure {
    text-align: center;
    display: inline-block;
    margin: 0px auto;
}

.AccountProfile figure span {
    display: inline-block;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 8px #ddd
}

.AccountProfile figure span img {
    width: 100%;
    height: 100%;
}

.AccountProfile .UploadOverlay {
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 30px;
    height: 30px;
}

.AccountProfile .UploadOverlay input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
}



.NotificationUl {
    display: flex;
    justify-content: space-between;
    margin: 0 0 25px 0;
}

.AgreementDetails .GeneralInformation {
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    margin: 0 0 15px 0;
    color: #000;
    font-size: 16px;
}

.ServiceDetails {
    display: flex;
    flex-wrap: wrap;
}

.ServiceDetails li {
    margin: 0 20px 10px 0;
    background-color: #e9e9e9;
    padding: 10px;
    border-radius: 5px;
    color: #000;
    position: relative;
}

.ServiceDetails li span {
    position: absolute;
    right: -5px;
    top: -10px;
    font-size: 15px;
}

.BuildingArea .BuildingPackage p span i.fa-location-dot {
    font-size: 16px;
    color: rgba(255, 144, 0, 1);
}

.MaskAsPaid {
    padding: 8px 10px;
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    min-width: 100px;
    background-color: #fd7325;
    border: none;
    outline: none;
    margin: 0 0 0 10px;
}

.MaskAsPaid:focus {
    outline: none;
}

.BgTransParent .CheckBox .checkmark {
    background-color: transparent;
}

.TableList .CheckBox .checkmark {
    background-color: transparent;
}

.OTPBox input {
    padding: 0;
    text-align: center !important;
    width: 55px !important;
    height: 55px;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    line-height: 30px;
    margin: 10px;
}


.load {
    width: auto;
    height: 5vh;
    display: flex;
    padding: 15vh 0;
    justify-content: center;
    align-items: center;
    color: #fd701e;
}





.pagination {
    padding: 5px 15px;
}

.pagination li {
    margin: 0px 10px 0px 0px;
}

.pagination li.active a {
    border-color: #F5F5FA !important;
    color: black !important;
    border-radius: 5px;
    background-color: #fd701e !important;
}

.pagination li a {
    float: left;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 18px;
    margin: 0px;
    border-color: #F5F5FA !important;
    border-radius: 5px;
    background-color: #F5F5FA !important;
    cursor: pointer;
}

.pagination li a:hover {
    background-color: #fd894e !important;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
}

.pagination a.prev {
    color: #8181A5;
    font-size: 12px;
    font-weight: 800;
}

.pagination a.prev img {
    margin: 0px 8px 0px 0px;
}

.pagination a.next img {
    margin: 0px 0px 0px 8px;
}

.pagination a.next {
    color: #8181A5;
    font-size: 12px;
    font-weight: 800;
}

.pagination .num a {
    margin: 0px 3px 0px 3px;
}

.error {
    color: red;
    font-size: 12px;
    text-align: left !important;
    margin-top: 4px;
}


.TableList table tr td span {
    display: inline-block;
    width: 50px;
    /* height: 50px; */
    /* border-radius: 20px; */
}


.Header .HeaderLeft h5 {
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 22px;
    color: rgba(255, 144, 0, 1);
}


.UploadBox .Upload{border:1px solid #afa8a8; width: 100%; display: flex; align-items: center; justify-content: center; padding: 13px 15px;border-radius: 5px;position:relative;text-align: center; line-height: 22px; background-color: #fff; border-radius: 30px; height: auto;}
.UploadBox .Upload i{font-size:16px; margin: 0 10px 0 0; color: #495057;}
.UploadBox .Upload span{font-size: 14px;font-weight:400;color:#495057;text-transform:capitalize;display: block;}
.UploadBox .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;opacity:0}
.UploadBox figure{margin:0 auto 0;width:170px}

/* .status{
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    min-width: 100px;
    background-color: orange;
} */
 .preview-img {
    border: 1px solid #ddd;
    border-radius: 10%;
    position: relative;
    width: 100px;
    /* height: 44px; */
    /* top: 15px; */
}


.cross-btn {
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    height: 20px;
    line-height: 19px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -10px;
    width: 20px;
}


.Title1{
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 22px;
    color: rgba(255, 144, 0, 1);
}